<!--
 * @Author: 杨佳 2337604439@qq.com
 * @Date: 2022-08-02 09:37:04
 * @LastEditors: 杨佳 2337604439@qq.com
 * @LastEditTime: 2022-08-04 09:57:54
 * @FilePath: \blog-service\src\components\container.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<el-container>
		<headline :isCollapse='isCollapse' @Collapse="checkCollapse"></headline>
		<el-aside style="width: auto;">
			<aside-menu :isCollapse='isCollapse'></aside-menu>
		</el-aside>
		<el-main :class="{ 'collapse-left': isCollapse }">
			<tabs ref="tabs">
				<slot></slot>
			</tabs>
		</el-main>
	</el-container>
</template>

<script>
import header from './header.vue'
import tabs from './tabs.vue'
import menu from './menu.vue'
export default {
	data() {
		return {
			isCollapse: false
		}
	},
	components: {
		tabs,
		'aside-menu': menu,
		'headline': header
	},
	methods: {
		checkCollapse(boolean) {
			if (boolean) {
				this.collapseLeft = true
			} else {
				this.collapseLeft = false
			}
			this.isCollapse = boolean
		}
	},
	mounted() {
		if (document.body.clientWidth < 1500) {
			this.isCollapse = true
		}
	}
}
</script>

<style lang="scss">
$top:70px;
.el-aside {
		background: #324157;
		position: fixed;
		top: $top;
		bottom: 0;
		left: 0;
	}
	.el-main{
		background: #f0f0f0;
		position: absolute;
		/* 高度为屏幕-70px */
		/* height: calc(100% - 70px); */
		top: $top;
		left: 250px;
		right: 0;
	}
	.collapse-left{
		transition: .3s left ;
		left: 64px!important;
	}
</style>
