/*
 * @Author: 杨佳 2337604439@qq.com
 * @Date: 2022-08-02 09:37:03
 * @LastEditors: 杨佳 2337604439@qq.com
 * @LastEditTime: 2022-08-05 16:23:07
 * @FilePath: \blog-service\src\api\user.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import http from '@/utils/axios'

function list(data) {
  return http({
    method: 'post',
    url: '/user/list',
    data
  })
}

function login(data) {
  return http({
    method: 'post',
    url: '/user/login',
    data
  })
}

function logout(data) {
  return http({
    method: 'post',
    url: '/user/logout',
    data,


  })
}

function register(data) {
  return http({
    method: 'post',
    url: '/user/register',
    data,


  })
}

function postUser(data) {
  return http({
    method: 'post',
    url: '/user/post',
    data,

  })
}

function update(data) {
  return http({
    method: 'post',
    url: '/user/update',
    data,
  })
}

function remove(data) {
  return http({
    method: 'post',
    url: '/user/remove',
    data,


  })
}

// 管理员直接修改密码
function adminpassword(data) {
  return http({
    method: 'post',
    url: '/user/adminpassword',
    data,


  })
}

function getUser(id){
  return http({
    method: 'post',
    url: '/user/get',
    data: {
      id
    }
  })
}

export {
  login,
  logout,
  register,
  postUser,
  list,
  update,
  remove,
  adminpassword,
  getUser
}