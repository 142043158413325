import axios from 'axios'
import store from '@/store/index'
import { baseURL, signConfig,dev } from '@/config'
import { Message,Loading } from 'element-ui'
import { getSignature, randString,encryptAES256 } from '@/utils/tool'
import router from '@/router'
let loading = null
function close(){
	if(loading){
		loading.close()
		loading = null
	}
}
const http = axios.create({
	baseURL,
	timeout: 30000,
	// 允许携带cookei
	withCredentials: true
})

http.interceptors.request.use(config => {
	loading = Loading.service({
		lock: true,
		text: '加载中...',
		spinner: 'el-icon-loading',
		background: 'rgba(0, 0, 0, 0.7)'
	})
	// if(config.url.indexOf('/login') == -1 & config.url.indexOf('/list') == -1 && config.url.indexOf('/get') == -1){
	// 	Message.error('暂未开放')
	// 	return Promise.reject("暂未开放")
	// }
	if (!dev) {
		let randStr = randString(16)
		let now = new Date()
		now.setMilliseconds(Math.random()*51)  //设置毫秒固定为0-50毫秒
		now = now.getTime()
		if (config.data)
			config.data = {
				data:encryptAES256(JSON.stringify(config.data),signConfig.secret,randStr),
				randStr
			}
		config.headers['signature'] = getSignature(signConfig.appid, signConfig.secret, 'kdgzgl', now)
		config.headers['timestamp'] = now
		config.headers['app_id'] = signConfig.appid
		config.headers['grant_type'] = 'kdgzgl'
	}
	config.headers['token'] = localStorage.getItem('token') || ""
	return config
}, err => {
	close()
	return Promise.reject(err)
})

http.interceptors.response.use(res => {
	close()
	if (res.data.code === 1000 && !res.config.url.includes('list')) {
		Message({
			message: "操作成功",
			type: 'success'
		})
	}
	if (res.data.code === 1006) {
		router.push("/login")
	}
	if (res.data.code !== 1000) {
		Message({
			message: res.data.message,
			type: 'error'
		})
	}
	return res.data
}, err => {
	close()
	if (err.message === "Network Error") {
		Message.error('无法连接服务器，请检查您的网络连接')
		return Promise.reject("无法连接服务器，请检查您的网络连接");

	} else if (err.message.indexOf("timeout of") > -1) {
		Message.error('客户端请求超时')
		return Promise.reject("客户端请求超时");

	} else {
		console.log('err :>> ', err);
		Message.error('未收到响应')
		return Promise.reject("未收到响应");
	}
})

export default http