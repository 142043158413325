import crypto from 'crypto'

function isWeixin() {
	let user = navigator.userAgent.toLowerCase()
	if (user.match(/MicroMessenger/i) == "micromessenger") {
		return true
	} else {
		return false
	}
}
function stringify(data) {
	const formData = new FormData()
	for (const key in data) {
		// eslint-disable-next-line no-prototype-builtins
		if (data.hasOwnProperty(key)) {
			if (data[key]) {
				if (data[key].constructor === Array) {
					if (data[key][0]) {
						if (data[key][0].constructor === Object) {
							formData.append(key, JSON.stringify(data[key]))
						} else {
							data[key].forEach((item, index) => {
								formData.append(key + `[${index}]`, item)
							})
						}
					} else {
						formData.append(key + '[]', '')
					}
				} else if (data[key].constructor === Object) {
					formData.append(key, JSON.stringify(data[key]))
				} else {
					formData.append(key, data[key])
				}
			} else {
				if (data[key] === 0) {
					formData.append(key, 0)
				} else {
					formData.append(key, '')
				}
			}
		}
	}
	return formData
}

function getSearch(hash, searchKey) {
	let search = ''
	if (hash) {
		let index = location.hash.indexOf('?')
		if (index > -1) {
			search = location.hash.substr(index)
		}
	} else
		search = location.search
	const obj = {}
	if (search.indexOf("?") == 0) {
		const arr = search.substr(1).split("&")
		arr.map(item => {
			item = item.split('=')
			if (item[0])
				obj[item[0]] = item[1]
		})
	}
	if (searchKey)
		return obj[searchKey]
	return obj
}

function getCookie(key) {
	const cookieObj = {}
	document.cookie.replace(/\s/g, '').split(';').map(item =>
		item.split('=')
	).map(item => {
		if (item.length >= 2 && item[0] !== '')
			cookieObj[item[0]] = item.splice(1).join('=')
	})
	if (key)
		return cookieObj[key]
	return cookieObj
}

function getRowHTML(obj) {
	if (Object.prototype.toString.call(obj) !== '[object Object]')
		return ''
	let message = ''
	for (let key in obj) {
		const item = {
			tag: obj[key] && obj[key].tag || 'div',
			content: obj[key] && obj[key].content || obj[key],
			class: obj[key] && obj[key].class || '',
			attr: obj[key] && obj[key].attr || '',
		}
		message += `
			<div class="flex-row" style="min-height: 2rem;">
				<div class="row-key pl-0 text-secondary font-weight-bold" style="flex-basis: 6rem"> 
					${key}
				</div>
				<${item.tag} 
					class="row-value ${item.class}" 
					${item.attr}>
					${item.content} 
				</${item.tag}>
			</div>`
	}
	return message
}

function randString(len) {
	len = len || 32
	const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkm!@np?rst_wx?yz2345678'
	/** **默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1*** */
	const maxPos = $chars.length
	let pwd = ''
	for (let i = 0; i < len; i++) {
		pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
	}
	return pwd
}
// app_id,secret,grant_type,timestamp
function getSignature(app_id, secret, grant_type, timestamp) {
	// 将args参数首字母按ascii排序?拼接成字符串
	let args = [
		'app_id=' + app_id,
		'grant_type=' + grant_type,
		'secret=' + secret,
		'timestamp=' + timestamp
	]
	let data = ''
	args.sort()
	args.forEach((e, index) => {
		if (index < args.length - 1)
			data += `${e}&`
		else
			data += e
	})
	const hmac = crypto.createHmac('sha256', secret);
	hmac.update(data);
	const hmacResult = hmac.digest('hex');
	// 字母大写
	return hmacResult.toUpperCase();;
}

// 加密函数
function encryptAES256(data, key, ranStr) {
	const cipher = crypto.createCipheriv('aes-256-cbc', key, ranStr);
	let encrypted = cipher.update(data, 'utf8', 'base64');
	encrypted += cipher.final('base64');
	return encrypted;
}


export {
	stringify,
	isWeixin,
	getSearch,
	getCookie,
	getRowHTML,
	randString,
	getSignature,
	encryptAES256
}
