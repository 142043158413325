<template>
  <el-menu :default-active="$route.path" class="el-menu-vertical-demo" background-color="#324157" text-color="#bfcbd9"
    active-text-color="#25a0e4" :collapse="isCollapse" :router="routerOpen" ref="elMenu">
    <el-menu-item index="/devide">
      <i class="el-icon-s-shop"></i>
      <span slot="title">设备管理</span>
    </el-menu-item>
    <!-- <el-menu-item index="/file">
      <i class="el-icon-files"></i>
      <span slot="title">文件管理</span>
    </el-menu-item> -->
    <el-menu-item index="/user">
      <i class="el-icon-s-custom"></i>
      <span slot="title">用户管理</span>
    </el-menu-item>
  </el-menu>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["isCollapse"],
  data() {
    return {
      routerOpen: true,
    };
  },
  methods: {
    // 判断用户权限是否足够
    auth() { },
  },
  mounted() { },
  computed: {
    ...mapState("user", ["info"]),
  },
};
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 250px;
}

.el-menu {
  border-right: none !important;
}
</style>
