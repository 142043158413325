import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import './utils/elementConfig'
// import {i18n} from './plugins/i18n.js'
import 'nprogress/nprogress.css'
import "@/utils/filter.js"
import config from "@/config/index.js"
import JsonExcel from "vue-json-excel"; //excel导出组件
Vue.component("downloadExcel", JsonExcel);
//引入打印插件
import Print from '@/utils/print';
Vue.use(Print)
// import "@/utils/rem.js"
Vue.prototype.$config = config

// 加密模块需要用到
Vue.config.productionTip = false
import * as buffer from "buffer"; //引入buffer
if (typeof window.Buffer === "undefined") { // 判断当前环境是否有Buffer对象
  window.Buffer = buffer.Buffer; // Buffer对象不存在则创建导入的buffer
}
// 加密模块需要用到
new Vue({
  router,
  store,
  // i18n,
  render: h => h(App)
}).$mount('#app')
