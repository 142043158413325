const dev = process.env.NODE_ENV == 'development' // 是否是开发环境
const baseURL = dev ? 'http://127.0.0.1:11115' : 'https://servr.device.klcxy.com' //资源地址
const serverURL = dev ? 'http://127.0.0.1:11115' : 'https://servr.device.klcxy.com' //后台地址
const wsURL = dev ? 'ws://127.0.0.1:8888' : 'ws://127.0.0.1:8888'	//websocket地址

const wxConfig = {
	appid: dev ? 'wx3ca027e0ad938d17' : 'wx80cc913bf467fd88',// 公众号appid
	redirect_uri: dev ? 'http://127.0.0.1:8080' : 'http://127.0.0.1:11111',// 跳转地址
	response_type: 'code',// 授权类型
	scope: 'snsapi_userinfo',// 授权作用域
	state: 'STATE'// 授权后重定向地址
}

const signConfig = {
	appid: "d74dfrf874@casdwq1qq35",
	secret: "di2d4tnXu~k6}!~D14ol941a|g74)&w+"
}
const title = '产品管理系统'
module.exports = {
	baseURL,
	serverURL,
	wsURL,
	wxConfig,
	title,
	signConfig,
	dev
}