<template>
	<div id="app" v-if="isLoad">
		<!-- <Header windows="main"></Header> -->
		<container v-if="!$route.meta.allScreen">
			<keep-alive>
				<router-view v-if="$route.meta.auth" />
			</keep-alive>
			<router-view v-if="!$route.meta.auth" />
		</container>
		<router-view v-else />
	</div>
</template>

<script>
import container from '@/components/container'
// electron 项目打开
// import Header from '@/components/electron/Header.vue'; 
export default {
	components: {
		container,
		// electron 项目打开
		// Header
	},
	data() {
		return {
			isShow: true,
			isLoad: true
		}
	},
	async mounted() {
		// this.isLoad = true
		// await this.$store.dispatch('ws/webSocketInit')
	}
}
</script>

<style lang="less">
@import url('./assets/css/component.css');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	width: 100%;
	height: 100%;
	font-size: 12px;
	font-family: PingFang SC, Helvetica Neue, Helvetica, microsoft yahei, arial, STHeiTi, sans-serif;
}
#app {
	width: 100%;
	height: 100%;
}
.el-message {
	z-index: 999999 !important;
}
.el-table__fixed-body-wrapper tr:not(.el-table__row):not(.el-table__row--striped) {
    /* 你的样式 */
	visibility: hidden;
}
.el-select-dropdown__item{
	display: flex;
	flex-direction: column;
	height: auto !important;
}
//设置打印时表格样式，防止打印位置不准确
@media print {
	.el-main {
		top: 0px !important;
		transform: translateX(-30px) !important;
	}
	.is-scrolling-none {
		overflow: hidden !important;
	}
}
</style>
