import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import { Message } from 'element-ui'
import store from '@/store'
Vue.use(VueRouter)
// permission 访问所需权限	0游客 1普通用户	2管理员（帖子，评论，审核） 3博主 4审核员
// record 是否需要记录访问记录 true/false
// auth 是否缓存页面 true/false
// allScreen 是否全屏展示
const routes = [
	{
		path: '/',
		name:'欢迎页',
		component: () => import('@/views/welcome'),
	},
	{
		path: '/user',
		component: () => import('@/views/user/Index'),
		name: '用户管理',
		meta: {
			permission: [888],
			record: true,
			auth: false
		}
	},
	{
		path: '/devide',
		component: () => import('@/views/devide/Index'),
		name: '设备管理',
		meta: {
			permission: [888],
			record: true,
			auth: false
		}
	},
	{
		path: '/display/:id',
		component: () => import('@/views/display/Index'),
		name: '信息校验',
		meta: {
			record: false,
			auth: false,
			allScreen: true
		}
	},
	{
		path: '/file',
		component: () => import('@/views/file/Index'),
		name: '文件管理',
		meta: {
			permission: [888],
			record: true,
			auth: false
		}
	},
	{
		path: '/production',
		component: () => import('@/views/production/Index'),
		name: '产品管理',
		meta: {
			permission: [888],
			record: true,
			auth: false
		}
	},
	{
		path: '/404',
		name: '404',
		component: () => import('@/views/404'),
		meta: {
			auth: false,
			allScreen: true
		}
	},
	{
		path: '/403',
		name: '权限不足',
		component: () => import('@/views/403'),
		meta: {
			auth: false,
			allScreen: true
		}
	},
	{
		path: '/login',
		name: '登录/注册',
		component: () => import('@/views/login'),
		meta: {
			auth: false,
			allScreen: true
		}
	},
	{
		path: '*',
		redirect: '/404',
		allScreen: true

	}
]
const router = new VueRouter({
	routes
})

router.beforeEach(async (to, from, next) => {
	NProgress.start()
	if (to.name) {
		document.title = to.name
	}
	if (to?.meta?.permission) {
		// 获取用户数据
		let data = localStorage.getItem('userInfo')
		if(data) data = JSON.parse(data)
		if (!data?.id) {
			router.push('/login')
			return next()
		}
		if (!to.meta?.permission?.some(item => item == data.type)) {
			Message.error('权限不足');
			console.log('权限不足');
			return next()
		}
	}
	NProgress.done()
	next()
})

export default router