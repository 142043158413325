<template>
	<div class="header" style="z-index:2001">
		<div class="collapse-btn" @click="changeCollapse">
			<i :class="{ 'el-icon-s-fold': isCollapse, 'el-icon-s-unfold': !isCollapse }"></i>
		</div>
		<!-- <div class="sysTitle">
			多源固废协同焚烧和二次污染物原始排放特征数据库
		</div> -->
		<div class="header-right">
			<div class="header-user-con">
				<!-- 全屏显示 -->
				<div class="btn-fullscreen" @click="handleFullScreen">
					<el-tooltip effect="dark" :content="fullscreen ? `取消全屏` : `全屏`" placement="bottom">
						<i class="el-icon-rank"></i>
					</el-tooltip>
				</div>

				<!-- 用户头像 -->
				<!-- <div class="user-avator">
		                </div> -->
				<!-- 用户名下拉菜单 -->
				<el-dropdown class="user-name" trigger="click" @command="handleCommand" v-if="info && info.id != 0">
					<span class="el-dropdown-link">
						<span>{{ info ? info.name : '' }}</span>
						<i class="el-icon-caret-bottom"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<!-- <el-dropdown-item command='myinfo'>个人信息</el-dropdown-item> -->
						<el-dropdown-item command="loginout">退出登录
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>

				<span v-else class="user-name cursor-pointer" style="font-size:14px;" @click="login()">
					{{ info ? info.name : '登录' }}
				</span>

			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
	props: ['isCollapse'],
	data() {
		return {
			fullscreen: false,
			message: 2,

		}
	},
	methods: {
		...mapActions('user', ['logout',]),
		login() {
			// 如果vuex得user的id为0，则跳转到登录页面
			
			this.$router.push('/login')
			
		},
		changeCollapse() {
			this.$emit('Collapse', !this.isCollapse)
		},
		async handleCommand(command) {
			if (command == 'loginout') {
				let res = await this.logout()
				if (res.code == 1000) {
					this.$message.success('退出成功')
					this.$router.push('/login')
				}
			}
			else if (command == 'myinfo') {
				this.$router.push('/myinfo');
			}
		},
		handleFullScreen() {
			let element = document.documentElement;
			if (this.fullscreen) {
				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.webkitCancelFullScreen) {
					document.webkitCancelFullScreen();
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else if (document.msExitFullscreen) {
					document.msExitFullscreen();
				}
			} else {
				if (element.requestFullscreen) {
					element.requestFullscreen();
				} else if (element.webkitRequestFullScreen) {
					element.webkitRequestFullScreen();
				} else if (element.mozRequestFullScreen) {
					element.mozRequestFullScreen();
				} else if (element.msRequestFullscreen) {
					// IE11
					element.msRequestFullscreen();
				}
			}
			this.fullscreen = !this.fullscreen;
		}
	},
	computed: {
		userPhone() {
			let userPhone = this.$store.state.user.info ? this.$store.state.user.info.userPhone : '未登录'
			return userPhone
		},
		...mapState('user', ['info'])
	},
	mounted() {
	}
}
</script>

<style>
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 70px;
	position: fixed;
	z-index: 999;
	background: #242f42;
}

a {
	text-decoration: none;
}

.collapse-btn {
	cursor: pointer;
	font-size: 25px;
	/* height: 100%; */
	float: left;
	padding: 0 21px;
	line-height: 70px;
	color: #fff;
}

.collapse-btn:hover {
	background: rgba(255, 255, 255, .3);
}

.sysTitle {
	color: #fff;
	line-height: 70px;
	font-size: .15rem;
	float: left;
	font-weight: bold;
}

.header-right {
	float: right;
	padding-right: 50px;
	color: #fff;
}

.header-user-con {
	display: flex;
	height: 70px;
	align-items: center;
}

.btn-fullscreen {
	transform: rotate(45deg);
	margin-right: 5px;
	font-size: 24px;
}

.btn-bell,
.btn-fullscreen {
	position: relative;
	width: 30px;
	/* height: 30px; */
	text-align: center;
	border-radius: 15px;
	cursor: pointer;
}

.btn-bell-badge {
	position: absolute;
	right: 0;
	top: -2px;
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background: #f56c6c;
	color: #fff;
}

.btn-bell .el-icon-bell {
	color: #fff;
}

.btn-bell {
	font-size: 22px
}

.user-name {
	margin-left: 10px;
}

.user-avator {
	margin-left: 20px;
}

.user-avator img {
	display: block;
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.el-dropdown-link {
	color: #fff;
	cursor: pointer;
}

.el-dropdown-menu__item {
	text-align: center;
}
</style>
