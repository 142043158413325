import { login, register, getUser, update, remove as removeUser, adminpassword, logout } from "@/api/user"
function getLocalStorageUser() {
    let user = localStorage.getItem("userInfo")
    if (!user) return null
    return JSON.parse(user)
}
const state = () => ({
    info: getLocalStorageUser(),
    token: localStorage.getItem("token") || ""
})

const mutations = {
    setInfo(state, payload) {
        state.info = payload
        localStorage.setItem('userInfo', JSON.stringify(payload))
    },
    setCookei(state, payload) {
        state.token = payload
        localStorage.setItem('token', payload)
    },
    deleteAllCookies() {
        // 获取当前域下的所有cookie
        var cookies = document.cookie.split(';');

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            // 去掉首尾空白字符
            var eqPos = cookie.indexOf('=');
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        }
    }
}

const actions = {
    login({ commit }, payload) {
        return new Promise((resolve, reject) => {
            login(payload).then(res => {
                if (res.code == 1000) {
                    commit('setCookei', res.token)
                    commit('setInfo', res.data)
                }
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    register({ commit }, payload) {
        return new Promise((resolve, reject) => {
            register(payload).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    put({ commit }, payload) {
        return new Promise((resolve, reject) => {
            update(payload).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    removeUser({ commit }, payload) {
        return new Promise((resolve, reject) => {
            removeUser(payload).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    getUser({ commit }, payload) {
        return new Promise((resolve, reject) => {
            getUser(payload).then(res => {
                if (res.code == 1000) {
                    commit('setInfo', res.data)
                }
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    adminpassword({ commit }, payload) {
        return new Promise((resolve, reject) => {
            adminpassword(payload).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    logout({ commit }, payload) {
        return new Promise((resolve, reject) => {
            logout(payload).then(res => {
                commit('setCookei', null)
                localStorage.removeItem('token')
                commit('deleteAllCookies')
                resolve(res)
                localStorage.removeItem("editableTabs")
            }
            ).catch(err => {
                reject(err)
            })
        })
    }
}

const getters = {

}

const user = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}

export default user